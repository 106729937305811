import {
  Box,
  Button,
  CircularProgress,
  Container,
  TextField,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React, { ChangeEvent, useContext, useState } from 'react';
import {
  BrandFormContext,
  IBrandFormContext,
} from '../../contexts/BrandFormContext';
import { BrandFormBox } from '../../scss/theme';

const BrandEmail: React.FC = () => {
  const { email, setEmail, submitData } =
    useContext<IBrandFormContext>(BrandFormContext);
  const [emailError, setEmailError] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    setLoading(true);
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError(!emailRegex.test(email));
      setLoading(false);
    } else {
      submitData()
        .then(() => {
          setLoading(false);
          navigate('/brandscent/success');
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Container maxWidth="sm">
      <Box sx={BrandFormBox}>
        <form onSubmit={handleSubmit} className="brand-form">
          <Typography variant="subtitle2" gutterBottom>
            End of the road
          </Typography>
          <Typography variant="h2" gutterBottom>
            Where should we send your Emotiontech™️ analysis results?
          </Typography>
          <Typography variant="body1">Submit your work-email please</Typography>
          <TextField
            error={emailError}
            helperText={emailError ? 'Invalid email' : ''}
            label="Email"
            type="email"
            onChange={handleEmailChange}
            value={email}
            autoFocus
            fullWidth
            margin="normal"
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ marginTop: '20px' }}
          >
            Continue
            {loading && <CircularProgress sx={{ marginLeft: '1rem' }} />}
          </Button>
        </form>
      </Box>
    </Container>
  );
};
export default BrandEmail;
