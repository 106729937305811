import { Box, Button, Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/logo-small.svg';
import { BrandFormBox } from '../../scss/theme';

function BrandStart() {
  const navigate = useNavigate();

  return (
    <Container maxWidth="md">
      <Box sx={BrandFormBox}>
        <img
          src={Logo}
          alt="NOS Emotiontech logo"
          style={{ width: '120px', marginBottom: '20px' }}
        />
        <Typography variant="h1">Welcome to the ai</Typography>
        <Typography variant="subtitle1">
          Get ready to experience your custom brand scent, created by AI. Let's
          get started! 🚀 🌟 🎉
        </Typography>
        <Typography variant="subtitle1">
          You will be asked a few questions about your brand and then you will
          be able to upload images that represent your brand.
        </Typography>
        <Button
          variant="contained"
          size="large"
          onClick={() => navigate('/brandscent/personname/')}
        >
          Start
        </Button>
      </Box>
    </Container>
  );
}

export default BrandStart;
