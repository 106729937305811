import {
  Box,
  Button,
  Container,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React, { useContext } from 'react';
import {
  BrandFormContext,
  IBrandFormContext,
} from '../../contexts/BrandFormContext';
import { BrandFormBox } from '../../scss/theme';

const BrandTime: React.FC = () => {
  const navigate = useNavigate();
  const { time, setTime } = useContext<IBrandFormContext>(BrandFormContext);
  const availableChoices = ['Experimental', 'Timeless'];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTime(event.target.value);
  };

  const handleSubmit = () => {
    navigate('/brandscent/physical');
  };

  return (
    <Container maxWidth="md">
      <Box sx={BrandFormBox}>
        <form onSubmit={handleSubmit} className="brand-form">
          <Typography variant="subtitle2" gutterBottom>
            Are you a Time Traveler?
          </Typography>
          <Typography variant="h2" gutterBottom>
            Are your brand more Timeless or Experimental?
          </Typography>
          <Grid container spacing={2}>
            {availableChoices.map((item) => (
              <Grid item xs={12} sm={6} key={item}>
                <RadioGroup value={time}>
                  <FormControlLabel
                    control={
                      <Radio value={item} onChange={handleChange} name={item} />
                    }
                    label={item}
                  />
                </RadioGroup>
              </Grid>
            ))}
          </Grid>
          <Button
            type="submit"
            variant="contained"
            disabled={!time}
            color="primary"
            style={{ marginTop: '20px' }}
          >
            Continue
          </Button>
        </form>
      </Box>
    </Container>
  );
};
export default BrandTime;
