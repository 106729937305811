import {
  Box,
  Button,
  Container,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React, { useContext } from 'react';
import {
  BrandFormContext,
  IBrandFormContext,
} from '../../contexts/BrandFormContext';
import InfoIcon from '@mui/icons-material/Info';
import { BrandFormBox } from '../../scss/theme';

const BrandPhysical: React.FC = () => {
  const navigate = useNavigate();
  const { physical, setPhysical } =
    useContext<IBrandFormContext>(BrandFormContext);
  const availableChoices = ['Thinking', 'Physical'];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhysical(event.target.value);
  };

  const handleSubmit = () => {
    navigate('/brandscent/colors');
  };

  return (
    <Container maxWidth="md">
      <Box sx={BrandFormBox}>
        <form onSubmit={handleSubmit} className="brand-form">
          <Typography variant="subtitle2" gutterBottom>
            INTRODUCTION
          </Typography>
          <Typography variant="h2" gutterBottom>
            Is your brand more Thinking (internal) or Physical (external)?{' '}
            <Tooltip title="Internal means blablabla, when blabla, and blabla. External is the constant strive... blabla.">
              <InfoIcon />
            </Tooltip>
          </Typography>
          <Grid container spacing={2}>
            {availableChoices.map((item) => (
              <Grid item xs={12} sm={6} key={item}>
                <RadioGroup value={physical}>
                  <FormControlLabel
                    control={
                      <Radio value={item} onChange={handleChange} name={item} />
                    }
                    label={item}
                  />
                </RadioGroup>
              </Grid>
            ))}
          </Grid>
          <Button
            type="submit"
            variant="contained"
            disabled={!physical}
            color="primary"
            style={{ marginTop: '50px' }}
          >
            Continue
          </Button>
        </form>
      </Box>
    </Container>
  );
};
export default BrandPhysical;
