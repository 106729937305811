import {
  Box,
  Button,
  CircularProgress,
  Container,
  Typography,
} from '@mui/material';

import { useNavigate } from 'react-router-dom';
import React, { FormEvent, useContext } from 'react';
import {
  BrandFormContext,
  IBrandFormContext,
} from '../../contexts/BrandFormContext';
import axios from 'axios';
import { compressFile } from '../event/Upload';
import { getB2BRecipe } from '../../api/api';
import UploadArea from '../../components/UploadArea';
import { BrandFormBox } from '../../scss/theme';

const BrandImages: React.FC = () => {
  const navigate = useNavigate();
  const { images, setImages, setRecipe, setImageUrlsToCloudinary } =
    useContext<IBrandFormContext>(BrandFormContext);
  const [loading, setLoading] = React.useState(false);

  const setSelectedFiles = (prevFile: Blob, newFile: Blob) => {
    const arr = [...images!.filter((file) => file !== prevFile)];
    const selectedFiles = newFile ? [...arr, newFile] : [...arr];
    setImages(selectedFiles);
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      const formData = new FormData();
      const promises = images.map((blob) => compressFile(blob));
      const compressedFiles = await Promise.all(promises);
      compressedFiles.forEach((f) => {
        formData.append('files', f);
      });
      const imageUploadRequest = {
        url: 'https://no-ordinary-scent-api.herokuapp.com/v1/upload_images',
        method: 'POST',
        data: formData,
      };
      const { data } = await axios(imageUploadRequest);
      const records: Record<'_url', string>[] = [];
      data.forEach((url: string) => {
        records.push({ _url: url });
      });
      setImageUrlsToCloudinary(data.join(', '));

      const recipeData = await getB2BRecipe(records);
      setRecipe(recipeData);
      navigate('/brandscent/email');
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <Container maxWidth="md">
      <Box sx={BrandFormBox}>
        <form onSubmit={handleSubmit} className="brand-form">
          <Typography variant="subtitle2" gutterBottom>
            Imagine your mind as a blank canvas
          </Typography>
          <Typography variant="h2">
            Upload the images that will be analyzed using AI
          </Typography>
          <Typography variant="body1">
            Use images corresponding to your brand. It is not possible to
            control the final formula through strategic images, meaning A rose
            image doesn't equal a rose scent.
          </Typography>
          <UploadArea
            setSelectedFiles={(prevFile: Blob, newFile: Blob) =>
              setSelectedFiles(prevFile, newFile)
            }
          />
          <Button
            type="submit"
            variant="contained"
            disabled={images.length !== 3 || loading}
            color="primary"
          >
            Upload
            {loading && <CircularProgress sx={{ marginLeft: '1rem' }} />}
          </Button>
        </form>
      </Box>
    </Container>
  );
};
export default BrandImages;
