import { Box, Button, Container, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React, { useContext } from 'react';
import {
  BrandFormContext,
  IBrandFormContext,
} from '../../contexts/BrandFormContext';
import { BrandFormBox } from '../../scss/theme';

const BrandPersonName: React.FC = () => {
  const { personName, brandName, setBrandName } =
    useContext<IBrandFormContext>(BrandFormContext);
  const navigate = useNavigate();

  const handleSubmit = () => {
    navigate('/brandscent/core');
  };

  return (
    <Container maxWidth="md">
      <Box sx={BrandFormBox}>
        <form onSubmit={handleSubmit} className="brand-form">
          <Typography variant="subtitle2" gutterBottom>
            Great to meet you {personName}!
          </Typography>
          <Typography variant="h2">
            What's your <b>brand</b> name?
          </Typography>
          <TextField
            label="Brand"
            onChange={(e) => setBrandName(e.target.value)}
            value={brandName}
            autoFocus
            fullWidth
            margin="normal"
          />
          <Button
            type="submit"
            disabled={!brandName}
            variant="contained"
            color="primary"
            style={{ marginTop: '20px' }}
          >
            Continue
          </Button>
        </form>
      </Box>
    </Container>
  );
};
export default BrandPersonName;
