import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React, { useContext, useState } from 'react';
import {
  BrandFormContext,
  IBrandFormContext,
} from '../../contexts/BrandFormContext';
import { ICheckedItems } from '../../types/types';
import { BrandFormBox } from '../../scss/theme';

const BrandEmotions: React.FC = () => {
  const navigate = useNavigate();
  const { setEmotions } = useContext<IBrandFormContext>(BrandFormContext);
  const [availableEmotions, setAvailableEmotions] = useState([
    'Curious',
    'Mystical',
    'Passionate',
    'Soft',
    'Edgy',
    'Elegant',
    'Secretive',
    'Playful',
    'Cold',
    'Natural',
    'Hopeful',
    'Strong',
    'Independent',
    'Sparkling',
    'Happy',
    'Dreamy',
  ]);

  const [showNewEmotionInput, setShowNewEmotionInput] = useState(false);
  const [newEmotion, setNewEmotion] = useState('');
  const [checkedItems, setCheckedItems] = useState<ICheckedItems>(
    availableEmotions.reduce(
      (emotion, item) => ({ ...emotion, [item]: false }),
      {}
    )
  );

  const addEmotion = () => {
    if (newEmotion) {
      setAvailableEmotions([...availableEmotions, newEmotion]);
      setCheckedItems({
        ...checkedItems,
        [newEmotion]: false,
      });
    }
    setShowNewEmotionInput(false);
    setNewEmotion('');
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });
  };

  const handleSubmit = () => {
    setEmotions(
      Object.keys(checkedItems).filter((key) => checkedItems[key].valueOf())
    );
    navigate('/brandscent/personality');
  };

  return (
    <Container maxWidth="md">
      <Box sx={BrandFormBox}>
        <form onSubmit={handleSubmit} className="brand-form">
          <Typography variant="subtitle2" gutterBottom>
            Emotions are a powerful tool to connect with your audience.
          </Typography>
          <Typography variant="h2" gutterBottom>
            Which emotions do you associate with your brand?
          </Typography>
          <Grid container spacing={2}>
            {availableEmotions.map((item) => (
              <Grid item xs={12} sm={6} md={4} key={item}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedItems[item]}
                      onChange={handleChange}
                      name={item}
                    />
                  }
                  label={item}
                />
              </Grid>
            ))}
          </Grid>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: '2rem',
            }}
          >
            {!showNewEmotionInput && (
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => setShowNewEmotionInput(true)}
              >
                Add another emotion
              </Button>
            )}
            {showNewEmotionInput && (
              <FormGroup>
                <TextField
                  label="Emotion"
                  margin="normal"
                  onChange={(e) =>
                    setNewEmotion((e.target as HTMLInputElement).value)
                  }
                  InputProps={{
                    endAdornment: (
                      <Button variant="text" onClick={addEmotion}>
                        Add
                      </Button>
                    ),
                  }}
                />
              </FormGroup>
            )}
          </Box>
          <br />
          <Button
            type="submit"
            variant="contained"
            disabled={Object.values(checkedItems).every((value) => !value)}
            color="primary"
            style={{ marginTop: '20px' }}
          >
            Continue
          </Button>
        </form>
      </Box>
    </Container>
  );
};
export default BrandEmotions;
