import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import downArrow from '../../assets/icons/downArrow.svg';
import Bottle from '../../components/Bottle';
import { rowContainer, rowItem } from '../../util/animations';
import { Grid, Stack, TextField } from '@mui/material';
import { placeShopifyOrder } from '../../api/api';
import { EventFormContext } from '../../contexts/EventFormContext';
import { Order } from '../../util/order';

type Props = {
  windowWidth: number;
};
export default function Cart({ windowWidth }: Props) {
  const navigate = useNavigate();
  const {
    recipe,
    personName,
    setEmail,
    email,
    perfumeName,
    setCompanyName,
    setFullName,
    companyName,
    fullName,
    submitData,
  } = useContext(EventFormContext);
  useEffect(() => {
    if (!recipe) {
      navigate('/');
    }
  }, []);

  const [viewDetails, setViewDetails] = useState(windowWidth > 768);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [loading, setLoading] = useState(false);

  //  On input, if valid then continue, if not valid then disable "Place order"-button
  const validateAndSetEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email.length && emailRegex.test(email)) {
      setIsValidEmail(true);
      setEmail(email);
    } else {
      setIsValidEmail(false);
      setEmail('');
    }
  };

  const placeOrder = async () => {
    if (loading || !isValidEmail) return;
    try {
      setLoading(true);

      // send info to shopify as backup in case pipedrive fails
      const order: Order & { companyName: string; fullName: string } = {
        orderId: '31340782977103',
        recipe: recipe,
        customerName: personName,
        email,
        perfumeName,
        companyName,
        fullName,
      };

      await placeShopifyOrder(order);
      window.sessionStorage.removeItem('recipeData');
      window.sessionStorage.removeItem('order');
      // maybe should clearContext() here ?
      await submitData();
    } catch (error) {
      console.error(error);
      navigate('/error');
    } finally {
      //setLoading(false)
      navigate('../success');
    }
  };

  function displayNotes() {
    let notes = window.sessionStorage.getItem('recipeData');
    if (typeof notes !== 'string') return {};
    const { mix } = JSON.parse(notes);
    const ret: { [index: string]: string } = {};

    Object.keys(mix).forEach((layer) => {
      ret[layer] = mix[layer].displayName
        .trim()
        .split(',')
        .map((string: string) => string.trim());
    });

    return {
      top: [...ret.top].slice(0, 4),
      heart: [...ret.heart.slice(0, 4)],
      base: [...ret.base.slice(0, 4)],
    };
  }

  return (
    <div
      className={`${
        windowWidth < 768 ? 'display-contents' : 'grid-area-bottle'
      }`}
    >
      <div
        style={windowWidth < 768 ? { paddingTop: '32px' } : {}}
        className="flex-column cart-headline"
      >
        <h1 className="title">{perfumeName}</h1>
        <h2 className="subtitle">is now created</h2>
      </div>
      <Bottle customerName={personName} perfumeName={perfumeName} />

      {!viewDetails && (
        <div
          style={{ cursor: 'pointer', paddingBottom: '16px' }}
          onClick={() => setViewDetails((curr) => !curr)}
          className="flex-row"
        >
          <span className="font-20-120">View {perfumeName} details</span>
          <img src={downArrow} height={20} alt="" />
        </div>
      )}

      {/* FINAL NOTES */}

      <AnimatePresence>
        {viewDetails && (
          <motion.div
            variants={rowContainer}
            initial="hidden"
            animate="show"
            exit="hidden"
            style={{ gap: '16px', paddingBottom: '16px' }}
            id="final-notes"
            className="flex-column full-width"
          >
            <motion.div variants={rowItem} className="grid-row-2">
              <motion.span>Top notes</motion.span>
              <motion.div className="flex-column left-align">
                {displayNotes() &&
                  displayNotes().top?.map((note) => (
                    <span key={note}>{note}</span>
                  ))}
              </motion.div>
            </motion.div>
            <motion.div variants={rowItem} className="grid-row-2">
              <motion.span>Heart notes</motion.span>
              <motion.div className="flex-column left-align">
                {displayNotes() &&
                  displayNotes().heart?.map((note) => (
                    <span key={note}>{note}</span>
                  ))}
              </motion.div>
            </motion.div>
            <motion.div variants={rowItem} className="grid-row-2">
              <motion.span>Base notes</motion.span>
              <motion.div className="flex-column left-align">
                {displayNotes() &&
                  displayNotes().base?.map((note) => (
                    <span key={note}>{note}</span>
                  ))}
              </motion.div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {!(windowWidth >= 768) && (
        <Stack spacing={3} width="100%" mb={5}>
          <TextField
            margin="none"
            label="Your full name"
            fullWidth
            onChange={(event) => setFullName(event.target.value)}
          />
          <TextField
            margin="none"
            label="Company"
            fullWidth
            onChange={(event) => setCompanyName(event.target.value)}
          />
          <TextField
            type="email"
            label="Email"
            fullWidth
            onChange={(event) => validateAndSetEmail(event.target.value)}
          />

          <button
            type="button"
            disabled={!isValidEmail || loading}
            onClick={placeOrder}
            className={`btn full-width ${
              !email || !isValidEmail || loading ? 'disabled' : ''
            }`}
            style={{ alignSelf: 'end' }}
          >
            Place order
          </button>
        </Stack>
      )}
      {windowWidth >= 768 && (
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              label="Your full name"
              fullWidth
              onChange={(event) => setFullName(event.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              sx={{ display: 'inline-flex' }}
              label="Company"
              fullWidth
              onChange={(event) => setCompanyName(event.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="email"
              label="Email"
              fullWidth={true}
              onChange={(event) => validateAndSetEmail(event.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <button
              type="button"
              disabled={!isValidEmail || loading}
              onClick={placeOrder}
              className={`btn full-width ${
                !email || !isValidEmail || loading ? 'disabled' : ''
              }`}
              style={{ alignSelf: 'end' }}
            >
              Place order
            </button>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
