import {
  Box,
  Button,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React, { useContext, useState } from 'react';
import {
  BrandFormContext,
  IBrandFormContext,
} from '../../contexts/BrandFormContext';
import { BrandFormBox } from '../../scss/theme';

const BrandPersonality: React.FC = () => {
  const navigate = useNavigate();
  const { personality, setPersonality } =
    useContext<IBrandFormContext>(BrandFormContext);
  const availablePersonalities = [
    'Connected',
    'Active',
    'Peaceful',
    'Eccentric',
    'Sensual',
    'Powerful',
    'Reliable',
  ];
  const [showNewPersonalityInput, setShowNewPersonalityInput] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowNewPersonalityInput(false);
    setPersonality(event.target.value);
  };

  const handleOther = () => {
    setShowNewPersonalityInput(true);
    setPersonality('');
  };

  const handleSubmit = () => {
    navigate('/brandscent/time');
  };

  return (
    <Container maxWidth="md">
      <Box sx={BrandFormBox}>
        <form onSubmit={handleSubmit} className="brand-form">
          <Typography variant="subtitle2" gutterBottom>
            PERSONALITY
          </Typography>
          <Typography variant="h2" gutterBottom>
            Describe your brand's ideal personality
          </Typography>
          <Grid container spacing={2}>
            {availablePersonalities.map((item) => (
              <Grid item xs={12} sm={6} md={4} key={item}>
                <RadioGroup value={personality}>
                  <FormControlLabel
                    control={
                      <Radio value={item} onChange={handleChange} name={item} />
                    }
                    label={item}
                  />
                </RadioGroup>
              </Grid>
            ))}
            <Grid item xs={12} sm={6} md={4} key="Other">
              <RadioGroup value={personality}>
                <FormControlLabel
                  control={
                    <Radio
                      value={showNewPersonalityInput}
                      checked={showNewPersonalityInput}
                      onChange={handleOther}
                      name="Other"
                    />
                  }
                  label="Other"
                />
              </RadioGroup>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {showNewPersonalityInput && (
              <FormGroup>
                <TextField
                  label="Personality"
                  margin="normal"
                  onChange={(e) =>
                    setPersonality((e.target as HTMLInputElement).value)
                  }
                />
              </FormGroup>
            )}
          </Box>
          <Button
            type="submit"
            variant="contained"
            disabled={!personality}
            color="primary"
            style={{ marginTop: '20px' }}
          >
            Continue
          </Button>
        </form>
      </Box>
    </Container>
  );
};
export default BrandPersonality;
